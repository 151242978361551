import React from "react";

import {
    Create,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    ArrayInput,
    ImageInput,
    ImageField,
    SelectArrayInput,
    SelectInput,
    required,
} from "react-admin";

import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DateTimeInput } from "react-admin-date-inputs2";

import RichTextInput from "ra-input-rich-text";

import { defaultStyle, arrayInputStyle } from "../../style";
import myDataProfider from "../.././myDataProvider";

const PostsCreate = (props) => {
    const [selectedDate, handleDateChange] = React.useState(new Date());
    const [categories, setCategories] = React.useState([]);
    const [teachers, setTeachers] = React.useState([]);
    const [postType, setPostType] = React.useState([]);

    React.useEffect(() => {
        myDataProfider
            .getList("categories", {
                pagination: { page: 1 },
                sort: { order: "ASC" },
            })
            .then(({ data }) => {
                setCategories(data);
            });
        myDataProfider
            .getList("teachers", {
                pagination: { page: 1 },
                sort: { order: "ASC" },
            })
            .then(({ data }) => {
                setTeachers(data);
            });
        myDataProfider
            .getList("postType", {
                pagination: { page: 1 },
                sort: { order: "ASC" },
            })
            .then(({ data }) => {
                setPostType(data);
            });
    }, []);

    return (
        <Create {...props} title="Новый пост">
            <SimpleForm>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <DateTimeInput
                        options={{
                            inputVariant: "outlined",
                            format: "yyyy-MM-dd, HH:mm",
                        }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        source="date"
                        label="Дата создания поста"
                        validate={[required()]}
                        style={defaultStyle}
                    />

                    <TextInput
                        source="url"
                        label="Имя ссылка"
                        validate={[required()]}
                        style={defaultStyle}
                    />
                    <TextInput
                        source="title"
                        label="Имя поста"
                        validate={[required()]}
                        style={defaultStyle}
                    />
                    <TextInput
                        source="smallDescription"
                        label="Краткое описание"
                        validate={[required()]}
                        style={defaultStyle}
                        multiline
                    />
                    {categories.length ? (
                        <SelectInput
                            label="Категория"
                            source="category"
                            choices={categories}
                            optionValue="key"
                            optionText="title"
                            validate={[required()]}
                            style={defaultStyle}
                        />
                    ) : null}
                    {postType.length ? (
                        <SelectInput
                            label="Тип"
                            source="type"
                            choices={postType}
                            optionValue="key"
                            optionText="title"
                            validate={[required()]}
                            style={defaultStyle}
                        />
                    ) : null}
                    {teachers.length ? (
                        <SelectArrayInput
                            label="Автор"
                            source="auth"
                            choices={teachers}
                            style={defaultStyle}
                        />
                    ) : null}
                    <ImageInput
                        source="thumb"
                        label="Изображение (максимальный размер 2МБ)"
                        maxSize="2000000"
                        accept="image/*"
                        placeholder={<p>Перетащите файл сюда</p>}
                        validate={[required()]}
                        style={defaultStyle}
                    >
                        <ImageField source="src" />
                    </ImageInput>
                    <ArrayInput
                        source="block"
                        label="Блоки"
                        style={defaultStyle}
                        validate={[required()]}
                    >
                        <SimpleFormIterator>
                            <TextInput
                                source="title"
                                label="Имя блока"
                                validate={[required()]}
                                style={arrayInputStyle}
                            />
                            <RichTextInput
                                label="Тело блока"
                                source="body"
                                validate={[required()]}
                                style={arrayInputStyle}
                            />
                            <ImageInput
                                source="thumbBlock"
                                label="Изображение (максимальный размер 2МБ)"
                                maxSize="2000000"
                                accept="image/*"
                                placeholder={<p>Перетащите файл сюда</p>}
                                style={arrayInputStyle}
                            >
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>

                    <TextInput
                        label="Произвольный JavaScript код (вверх страницы)"
                        source="postPageTopJs"
                        style={defaultStyle}
                        multiline
                    />
                    <TextInput
                        label="Теги HTML (вверх страницы)"
                        source="postPageTopHtml"
                        style={defaultStyle}
                        multiline
                    />
                    <TextInput
                        label="Произвольный JavaScript код (вниз страницы)"
                        source="postPageBottomJs"
                        style={defaultStyle}
                        multiline
                    />
                    <TextInput
                        label="Теги HTML (вниз страницы)"
                        source="postPageBottomHtml"
                        style={defaultStyle}
                        multiline
                    />
                </MuiPickersUtilsProvider>
            </SimpleForm>
        </Create>
    );
};

export default PostsCreate;
